import React from "react";
import {Typography, TextField} from "@material-ui/core";

const TextFieldRow = ({
  label,
  type = "text",
  className = "",
  ...props
}) => {
  return(
    <div className="form-group row mb-9">
      <Typography variant={"body1"} component={"label"} className={"pl-1 mb-1 font-weight-bold"}>
        {label}
      </Typography>
      <TextField
        variant="filled"
        className={`form-control rounded border-0 ${className}`}
        placeholder={label}
        type={type}
        {...props}
      />
    </div>
  )
}

export default TextFieldRow;
