import React from 'react';
import globalHook from 'use-global-hook';
import {formatDateRangeItem, cleanText} from "~/app/helpers/format_functions";

const fields = [
  {tab: 0, name: "name", label: "Name", type: "text", placeholder: "Your full name", multiple: false},
  {tab: 0, name: "email", label: "Email", type: "text", placeholder: "Your email", multiple: false},
  {tab: 0, name: "current_location", label: "Current location", type: "text", placeholder: "City, Country", multiple: false},
  {tab: 0, name: "current_employer", label: "Current employer", type: "text", placeholder: "Law firm name / Previously law firm name", multiple: false},
  {tab: 0, name: "title_and_practice_area", label: "Title and practice area", type: "text", placeholder: "Associate - area", multiple: false},
  {tab: 0, name: "partners_working_for", label: "Partners working for", type: "text", placeholder: "Hyperlinks for partner names", multiple: false},
  {tab: 0, name: "position_sought", label: "Position sought", type: "text", placeholder: "Are of law, firm, location", multiple: false},
  {tab: 0, name: "admission_date_and_location", label: "Admission date and location", type: "text", placeholder: "DD/MM/YY, E.g. New Zealand", multiple: false},
  {tab: 0, name: "commenced_as_a_graduate", label: "Commenced as a graduate", type: "text", placeholder: "MM/YY", multiple: false},
  {tab: 0, name: "education", label: "Education", multiple: true,
    subFields: [
      {name: "date_range", type: "date_range", placeholder: "MM/YYYY"},
      {name: "university_location", type: "text", placeholder: "University, Location"},
      {name: "course", type: "text", placeholder: "Course"},
    ]
  },
  {tab: 0, name: "nationality", label: "Nationality", type: "text", placeholder: "Nationality / Citizenship", multiple: false},
  {tab: 0, name: "working_rights", label: "Working rights(VISA)", type: "text", placeholder: "E.g. UK Ancestry Visa", multiple: false},
  {tab: 0, name: "availability_to_interview", label: "Availability to interview", type: "text", placeholder: "E.g. Immediately available via any VC platform and/or telephone", multiple: false},
  {tab: 0, name: "notice_period", label: "Notice period", type: "text", placeholder: "Notice period between dismissal and the end of last working day", multiple: false},
  {tab: 0, name: "preferred_start_date", label: "Preferred start date", type: "text", placeholder: "MM/YY", multiple: false},
  {tab: 0, name: "relocation_assistance_required", label: "Relocation assistance required", type: "text", placeholder: "Do you need relocation assistance?", multiple: false},
  {tab: 0, name: "languages", label: "Languages", type: "text", placeholder: "Languages", multiple: false},
  {tab: 1, name: "profile", label: "Profile", type: "ckeditor", placeholder: "", multiple: false},
  {tab: 2, name: "resume_applicant", label: "RESUME - NAME", type: "ckeditor", placeholder: "", multiple: false},
  {tab: 2, name: "admission", label: "Admission", type: "ckeditor", placeholder: "", multiple: false},
  {tab: 2, name: "education_and_qualification", label: "Education and Qualifications", multiple: true,
    subFields: [
      {name: "date_range", type: "date_range", placeholder: "MM/YYYY"},
      {name: "university_location", type: "text", placeholder: "University, Location"},
      {name: "course", type: "text", placeholder: "Course"},
    ]
  },
  {tab: 2, name: "awards_and_scholarships", label: "Awards and Scholarships", multiple: true,
    subFields: [
      {name: "date_range", type: "date_range", placeholder: "MM/YYYY"},
      {name: "university_location", type: "text", placeholder: "University, Location"},
      {name: "course", type: "text", placeholder: "Course"},
    ]
  },
  {tab: 2, name: "employment_history", label: "Employment History", multiple: true,
    subFields: [
      {name: "date_range", type: "date_range", placeholder: "MM/YYYY"},
      {name: "company_location", type: "text", placeholder: "Company, Location"},
      {name: "position", type: "text", placeholder: "Position"},
    ]
  },
  {tab: 2, name: "further_position_of_interest", label: "Further Positions of Interest", type: "ckeditor", placeholder: "", multiple: false },
  {tab: 2, name: "publications", label: "Publications", multiple: true,
    subFields: [
      {name: "company_location", type: "text", placeholder: "Company, Location"},
      {name: "position", type: "text", placeholder: "Position"},
    ]
  },
  {tab: 2, name: "interests", label: "Interests", type: "ckeditor", placeholder: "", multiple: false},
  {tab: 2, name: "references", label: "References", multiple: true,
    subFields: [
      {name: "name", type: "text", placeholder: "Name"},
      {name: "company_location", type: "text", placeholder: "Company, Location"},
      {name: "position", type: "text", placeholder: "Position"},
      {name: "email", type: "text", placeholder: "Email"},
      {name: "contact", type: "text", placeholder: "Contact"},
    ]
  },
];

const initItems = {
  education: {
    date_start: "",
    date_end: "",
    university_location: "",
    course: "",
  },
  education_and_qualification: {
    date_start: "",
    date_end: "",
    university_location: "",
    course: "",
  },
  awards_and_scholarships: {
    date_start: "",
    date_end: "",
    university_location: "",
    course: "",
  },
  employment_history: {
    date_start: "",
    date_end: "",
    company_location: "",
    position: ""
  },
  publications: {
    company_location: "",
    position: ""
  },
  references: {
    name: "",
    company_location: "",
    position: "",
    email: "",
    contact: ""
  }
};


const application = {
  id: "",
  user_id: "",
  recruiter_uuid: "",
  status: "For Review",
  name: "",
  email: "",
  current_location: "",
  current_employer: "",
  title_and_practice_area: "",
  position_sought: "",
  admission_date_and_location: "",
  commenced_as_a_graduate: "",
  education: [initItems.education],
  nationality: "",
  working_rights: "",
  availability_to_interview: "",
  notice_period: "",
  preferred_start_date: "",
  relocation_assistance_required: "",
  languages: "",
  profile: "",
  resume_applicant: "",
  admission: "",
  education_and_qualification: [initItems.education_and_qualification],
  awards_and_scholarships: [initItems.awards_and_scholarships],
  employment_history: [initItems.employment_history],
  further_position_of_interest: "",
  publications: [initItems.publications],
  interests: "",
  references: [initItems.publications],
};

const state = {
  initItems: initItems,
  fields: fields,
  application: JSON.parse(JSON.stringify(application)),
  application_clean: {},
  applications: [],
  listCount: 0,
  loading: false,
  filterOptions: {
    companies: [],
    positions: [],
  },
  lastParams: '',
};

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  resetApplication: (store) => {
    store.setState({application: JSON.parse(JSON.stringify(application))});
  },
  formatApplication: (store, application) => {
    let application_clean = {};

    for(let key of Object.keys(application)) {
      if(Array.isArray(application[key])) {
        let c_text = "";
        for(let i in application[key]) {
          if(!application[key][i]) {
            application[key][i] = {};
          } else {
            for(let k2 of Object.keys(application[key][i])) {
              application[key][i][k2] = application[key][i][k2] ? application[key][i][k2] : "";
              c_text += ["date_start", "date_end"].includes(k2) ? formatDateRangeItem(application[key][i][k2]) : application[key][i][k2];
            }
          }
        }
        application_clean[key] = cleanText(c_text);
      } else {
        application[key] = application[key] ? application[key] : "";
        application_clean[key] = cleanText(application[key]);
      }
    }

    return {
      application: application,
      application_clean: application_clean
    }
  },
  fetchApplication: async (store, id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/application/${id}`
      });

      // set null values to empty string
      let application =  {
        ...data.data,
        id: data.id,
        user_id: data.user_id,
        recruiter_uuid: data.recruiter_uuid,
        status: data.status
      };

      let formattedData = store.actions.formatApplication(application);
      store.setState(formattedData);

      return formattedData.application;
    }
    catch (error) {
      console.log(error);
      return false;
    }
  },
  fetchApplications: async (store, queryParams = null, filters = null) => {
    store.setState({ loading: true });
    try {
      let params = "";

      if(queryParams && filters) {
        params = `?page=${queryParams.pageNumber}
        &pageSize=${queryParams.pageSize}
        &sortField=${queryParams.sortField.replace(".", "->")}
        &sortOrder=${queryParams.sortOrder}
        &searchText=${filters.searchText}
        &company=${filters.company}
        &position=${filters.position}`;
      } else {
        params = store.state.lastParams;
      }

      let { data } = await global.axios({
        method: "GET",
        url: `/api/application${params}`
      });

      store.setState({
        applications: data.data,
        listCount: data.meta.total,
        loading: false,
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }
  },
  saveApplication: async (store, history = null) => {
    try {
      let config = {headers: { "Content-Type": "application/json" }};
      let payload = store.state.application;
      let url = `/api/application`;

      if(payload.id !== "") {
        url += `/${payload.id}`;
        payload["_method"] = "PUT";
      } else {
        payload.status = "For Review";
      }

      if(localStorage.user) {
        payload.user_id = JSON.parse(localStorage.user).id;
      }

      let { data } = await global.axios.post(url, payload, config);
      localStorage.setItem("isDoneSaving", true);

      if(payload.id === "" && history) {
        window.parent.location.href = `/applications/application-editor/${data.id}`;
      }

      let formattedData = store.actions.formatApplication(store.state.application);
      store.setState({ application_clean: formattedData.application_clean });

      return true;
    }
    catch ({ response }) {
      console.log("error", response);
      return false;
    }
  },
  deleteApplication: async (store, id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/application/${id}`
      });
      store.actions.fetchApplications();
      return true;
    }
    catch (error) {
      console.log(error);
      return false;
    }
  },
  savePdf: async (store, application_id, pdfBlob) => {
    try {
      const formData = new FormData();
      formData.append('application_id', application_id);
      formData.append('document', pdfBlob);

      let config = { headers: { "Content-Type": "multipart/form-data" } };
      let url = '/api/store-applicant-pdf';

      await global.axios.post(url, formData, config);

  } catch (error) {
      console.log(error);
  }
  }
};

const useApplication = globalHook(React, state, actions);

export default useApplication;
