import React from 'react';
import globalHook from 'use-global-hook';
import {formatDateRangeItem} from "~/app/helpers/format_functions";

const state = {
  loading: false,
  comments: [],
  highlights: [],
  allHighlights: [],
  openTicketsCount: "",
  hideClosedTickets: true,
  expanded: {},
  selectedStatus: "all",
  filteredHighlights: [],
  filterOptions: [
    {label: "All", value: "all"},
    {label: "Open", value: "open"},
    {label: "Closed", value: "closed"},
  ]
};

const actions = {
  setState: (store, newState) => {
    store.setState(newState);
  },
  cleanText: (store, text) => {
    let div = document.createElement("div");
    div.innerHTML = `<p>${text}</p>`;
    return div.innerText.replace(/ /g,'').replace(/-/g,'');
  },
  fetchComments: async (store, application_id) => {
    store.setState({ loading: true });
    try {
      let params = `?application_id=${application_id}`;
      
      let { data } = await global.axios({
        method: "GET",
        url: `/api/comment${params}`
      });

      let highlights = [];
      let allHighlights = [];
      if(data.data.length > 0) {
        let appData = data.data[0].application.data;
        let appText = "";

        for(let i of Object.keys(appData)) {
          if(Array.isArray(appData[i])) {
            for(let item of appData[i]) {
              for(let k of Object.keys(item)) {
                if(item[k]) {
                  if(["date_start", "date_end"].includes(k)) {
                    appText += ` ${formatDateRangeItem(item[k])}`
                  } else {
                    appText += ` ${item[k]}`;
                  }
                }
              }
            }
          } else {
            appText += ` ${appData[i]}`;
          }
        }
        appText = store.actions.cleanText(appText);

        for(let item of data.data) {
          let content = {
            ...item,
            ...item.content,
            id: String(item.id)
          }

          if(item.status !== "closed") {
            let contentText = store.actions.cleanText(content.content.text);
            if(appText.includes(contentText)) {
              highlights.push(content);
            } else {
              content.status = "closed";
              // store.actions.closeTicket(item.id, user_id, false);
            }
          }
          allHighlights.push(content);
        }
      }

      let filteredHighlights = store.actions.filterAllHighlights(allHighlights);

      store.setState({
        loading: false,
        comments: data.data,
        highlights: highlights,
        allHighlights: allHighlights,
        filteredHighlights: filteredHighlights
      });
    }
    catch (error) {
      store.setState({ loading: false });
      console.log(error);
    }
  },
  saveComment: async (store, comment) => {
    try {
      let config = {headers: { "Content-Type": "application/json" }};
      let url = `/api/comment`;

      if(comment.id === "") {
        comment.status = "open";
      }

      if(comment.id) {
        url += `/${comment.id}`;
        comment['_method'] = "PUT";
      }

      await global.axios.post(url, comment, config);
      await store.actions.fetchComments(comment.application_id);

      return true;
    }
    catch ({ response }) {
      console.log("error", response);
      return false;
    }
  },
  delete: async (store, id) => {
    try {
      await global.axios({
        method: "DELETE",
        url: `/api/comment/${id}`
      });
      store.actions.fetchComments();
    }
    catch (error) {
      console.log(error);
    }
  },
  countOpenTickets: async (store, application_id) => {
    try {
      let { data } = await global.axios({
        method: "GET",
        url: `/api/count-open-tickets?application_id=${application_id}`
      });
      store.setState({ openTicketsCount: data });
    }
    catch({ response }) {
        console.log(response);
    }
  },
  closeTicket: async (store, comment_id, user_id = "", reload_comments = true) => {
    try {
      await global.axios({
        method: "POST",
        data: {_method: "PUT"},
        url: `/api/close-ticket/${comment_id}?user_id=${user_id}`
      });
      if(reload_comments) {
        store.actions.fetchComments();
      }
      return true;
    }
    catch({ response }) {
      console.log(response);
    }
  },
  filterAllHighlights: (store, allHighlights = []) => {

    let filteredHighlights = [];
    allHighlights = allHighlights.length > 0 ? allHighlights : store.state.allHighlights;

    if(store.state.selectedStatus === "all") {
      filteredHighlights = allHighlights;
    } else {
      filteredHighlights = allHighlights.filter(item => item.status === store.state.selectedStatus);
    }

    store.setState({ filteredHighlights: filteredHighlights });

    return filteredHighlights;
  },
  sendMailToApplicant: async (store, data) => {
    try {
      await global.axios.post(`/api/send-mail-to-applicant`, data);
      return true;
    }
    catch ({ response }) {
      console.log("error", response);
      return false;
    }
  },
  sendMailToRecruiter: async (store, data) => {
    try {
      await global.axios.post(`/api/send-mail-to-recruiter`, data);
      return true;
    }
    catch ({ response }) {
      console.log("error", response);
      return false;
    }
  },
  updateHighlight: async (store, comment) => {
    try {
      await global.axios.post(`/api/update-comment-highlight/${comment.id}`, comment);
      return true;
    }
    catch ({ response }) {
      console.log("error", response);
      return false;
    }
  }
};

const useComment = globalHook(React, state, actions);

export default useComment;
