export const formatNumber = (number) => {
  return (number).toLocaleString(
    'en-US',
    {
      minimumFractionDigits: 2,
      maximumFractionDigits:2
    }
  );
}


const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const formatDateRange = (data) => {
  let dateString = "";

  if(data.hasOwnProperty("date_start") && data.date_start !== "") {
    dateString += formatDateRangeItem(data.date_start);
  }

  if(data.hasOwnProperty("date_end") && data.date_end !== "") {
    dateString += dateString !== "" ? " - " : "";
    dateString += formatDateRangeItem(data.date_end);
  }

  return dateString;
}

export const formatDateRangeItem = (dateItem) => {
  let arr = dateItem.split("/");
  return `${month[arr[0]-1]} ${arr[1]}`;
}


export const cleanText = (text) => {
  if(!text) return "";

  let cleanValue = JSON.parse(JSON.stringify(text));
  return String(cleanValue).replace( /(<([^>]+)>)/ig, '')
          .replace(/ /g,'').replace(/-/g,'');
}
