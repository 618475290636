import React from "react";

const PositionColumnFormatter = (cellContent, row) => {
  return (
    <div>
      {row.data.position_sought}
    </div>
  );
}

export default PositionColumnFormatter;
