import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
}));

const CustomTextFieldSelect = ({
   options = [], // [{label: label, value: value}, ...]
   optionLabelKey = "label",
   optionValueKey = "value",
   preOptions = "",
   ...props
}) => {
  const classes = useStyles();
  return (
    <span className={classes.root}>
      <TextField select variant="outlined" SelectProps={{ native: true }} {...props} >
        {preOptions && preOptions}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    </span>
  );
}

export default memo(CustomTextFieldSelect);
